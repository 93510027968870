<template>
  <div class="bulkImportPage" id="test">
    <div class="facilityBox">
      <div class="tableWrap">
        <div style="display: flex; justify-content: space-between; align-content: center">
          <el-breadcrumb separator="/" style="margin: 10px 0">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>人员管理</el-breadcrumb-item>
            <el-breadcrumb-item>导入车辆信息</el-breadcrumb-item>
          </el-breadcrumb>

          <el-tooltip placement="left">
            <div slot="content">
              1.草稿箱可暂存信息，防止丢失<br />
              2.必填证照齐全的可以提交平台审核，不齐全的需在“编辑”页继续补齐；<br />
              3.“批量上传” 页最多100条信息；<br />
              4.受您电脑带宽影响，信息量越大，证照越多，上传时间越久；<br />
              5.对于暂存的信息，如需再次修改或补充，请先勾选要补充的信息，再次点击“批量车辆上传”
            </div>
            <div style="margin: 0px 20px; color: #0f5fff; cursor: pointer">
              <i class="el-icon-question"></i>
              <span>帮助</span>
            </div>
          </el-tooltip>
        </div>

        <div style="margin: 20px 0 0 0; display: flex; justify-content: space-between">
          <el-form ref="form" :model="driverForm" label-width="90px" inline>
            <el-form-item label="车牌号" prop="VehicleCarNumber">
              <el-input v-model="driverForm.VehicleCarNumber" placeholder="请输车牌号">
              </el-input>
            </el-form-item>
            <el-form-item label="平台运力池" prop="IsInTCCode">
              <el-select v-model="driverForm.IsInTCCode" placeholder="请选择平台运力池">
                <el-option
                  v-for="item in IsInTCList"
                  :key="item.Code"
                  :label="item.Name"
                  :value="item.Code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                size="medium"
                @click="search()"
                icon="el-icon-search"
                >搜索</el-button
              >
              <el-button
                type="primary"
                size="medium"
                icon="el-icon-delete"
                @click="resetForm()"
                >清空</el-button
              >
            </el-form-item>
            <el-button
              type="primary"
              size="medium"
              icon="el-icon-upload2"
              @click="batchClick()"
              style="margin-top: 2px"
            >
              批量车辆上传</el-button
            >
            <el-button
              type="danger"
              size="medium"
              icon="el-icon-delete"
              :disabled="this.tableData.length == 0"
              @click="delClick()"
              >删除选中</el-button
            >
            <el-button
              type="danger"
              size="medium"
              icon="el-icon-delete"
              :disabled="this.batchData.length == 0"
              @click="delClickAll()"
              v-if="!getIsCarCaptain"
              >一键删除所有</el-button
            >
            <el-button
              type="primary"
              size="medium"
              icon="el-icon-download"
              @click="downloadClick()"
              >下载车辆表格</el-button
            >
            <el-button
              type="primary"
              size="medium"
              @click="download"
              icon="el-icon-download"
              >下载车辆表格模板</el-button
            >
            <el-button size="medium" @click="$router.back()" icon="el-icon-arrow-left"
              >返回</el-button
            >
          </el-form>
        </div>
        <el-table
          ref="multipleTable"
          :data="batchData"
          :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
          :height="tableHeight"
          @select="selectChange"
          @select-all="selectChange"
          :row-class-name="setDeletedRow"
          v-loading="batchDataLoading"
        >
          <el-table-column
            fixed
            align="center"
            type="selection"
            width="55"
          ></el-table-column>
          <el-table-column
            fixed
            align="center"
            type="index"
            label="序号"
            width="50"
          ></el-table-column>
          <el-table-column
            fixed
            align="center"
            prop="VehicleCarNumber"
            label="车牌号"
            width="120"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="CarNumberColorName"
            label="车牌颜色"
            width="100"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="VehicleTypeName"
            label="车辆类型"
            width="100"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="VehicleLoad"
            label="载重(吨)"
            width="100"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="VehicleLength"
            label="车长(米)"
            width="100"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="VehicleWidth"
            label="车宽(米)"
            width="100"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="VehicleRTP"
            label="道路运输证号"
            width="150"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="VehicleMaster"
            label="所有人"
            width="150"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="VehicleLoadWeight"
            label="总重(吨)"
            width="150"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="VehiclePowerTypeName"
            label="能源类型"
            width="150"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="VehicleLicesenAuthority"
            label="行驶证发证机关"
            width="150"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            align="center"
            prop="VehicleLoadPersons"
            label="核载人数"
            width="150"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            align="center"
            prop=""
            label="平台运力池"
            width="100"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-tag :type="scope.row.IsInTCName == '不存在' ? 'danger' : 'success'">
                {{ scope.row.IsInTCName }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="VehicleLicenseFrontPageURL"
            label="行驶证正页(正面)"
            width="150"
          >
            <template slot-scope="scope">
              <el-image
                v-if="scope.row.VehicleLicenseFrontPageURL"
                style="width: 200; height: 50px"
                :src="scope.row.VehicleLicenseFrontPageURL"
                :preview-src-list="[scope.row.VehicleLicenseFrontPageURL]"
              >
              </el-image>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="VehicleLicenseSubPageOnURL"
            label="行驶证副页(正面)"
            width="150"
          >
            <template slot-scope="scope">
              <el-image
                v-if="scope.row.VehicleLicenseSubPageOnURL"
                style="width: 200; height: 50px"
                :src="scope.row.VehicleLicenseSubPageOnURL"
                :preview-src-list="[scope.row.VehicleLicenseSubPageOnURL]"
              >
              </el-image>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="VehicleLicenseSubPageBackURL"
            label="行驶证副页(反面)"
            width="150"
          >
            <template slot-scope="scope">
              <el-image
                v-if="scope.row.VehicleLicenseSubPageBackURL"
                style="width: 200; height: 50px"
                :src="scope.row.VehicleLicenseSubPageBackURL"
                :preview-src-list="[scope.row.VehicleLicenseSubPageBackURL]"
              >
              </el-image>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="VehicleRTPUTL"
            label="道路运输证"
            width="150"
          >
            <template slot-scope="scope">
              <el-image
                v-if="scope.row.VehicleRTPUTL"
                style="width: 200; height: 50px"
                :src="scope.row.VehicleRTPUTL"
                :preview-src-list="[scope.row.VehicleRTPUTL]"
              >
              </el-image>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="AdditionalLicenseURL"
            label="附加证照"
            width="150"
          >
            <template slot-scope="scope">
              <el-image
                v-if="scope.row.AdditionalLicenseURL"
                style="width: 200; height: 50px"
                :src="scope.row.AdditionalLicenseURL"
                :preview-src-list="[scope.row.AdditionalLicenseURL]"
              >
              </el-image>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div style="margin-top: 10px">
          <el-pagination
            background
            class="pagination"
            @current-change="handleCurrentChange"
            @size-change="sizeChange"
            :current-page.sync="pagination.page"
            :page-size="pagination.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pagination.total"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 导入车辆信息dialog -->
      <el-dialog
        class="driverInfo"
        :visible.sync="flag.showDriverInfo"
        width="540px"
        top="35vh"
      >
        <span slot="title" class="dialog-title"> 上传车辆信息 </span>
        <div class="body">
          <el-alert
            type="warning"
            title="温馨提示：1.请按照excel字段规则填写;2.单次导入不超过500条数据;"
            :closable="false"
          >
          </el-alert>
          <input
            ref="driverInfo"
            type="file"
            style="display: none"
            @change="importDriverInfo"
          />
        </div>
        <span slot="footer">
          <el-button
            type="primary"
            size="medium"
            @click="VehicleExcel"
            icon="el-icon-upload2"
            >上传车辆表格</el-button
          >
        </span>
      </el-dialog>
      <!-- 导入车辆证件照dialog -->
      <el-dialog
        class="driverPhoto"
        top="20vh"
        :visible.sync="flag.showDriverPhoto"
        width="60%"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="!uploading"
      >
        <span slot="title" class="dialog-title"> 上传车辆证照 </span>
        <div class="body">
          <el-alert type="warning" :closable="false">
            <span slot="title">
              <span>温馨提示：</span><br />
              <span>1.限制一次性最多<span>上传100张</span></span
              ><br />
              <span>2.支持图片格式：<span>jpeg/jpg/png</span></span
              ><br />
              <span>3.车辆证照命名规则如下：</span><br />
              <span
                >行驶证正页：车牌号-1.jpg、
                行驶证副页(正面)：车牌号-2.jpg、行驶证副页(反面)：车牌号-3.jpg、
                道路运输证：车牌号-4.jpg、 附加证照：车牌号-5.jpg</span
              ><br />
              <span>如文件名与表格中不匹配将不显示在图片列表中，请仔细检查。</span><br />
            </span>
          </el-alert>
          <!-- 上传图片区域 -->
          <div class="uploadArea" v-if="!uploading">
            <div class="bulkImportList">
              <div class="item" v-for="(item, index) in bulkImportList" :key="index">
                <img :src="item.url" />
                <span>{{ item.name }}</span>
                <i class="el-icon-delete" @click="delListItem(index)"></i>
              </div>
            </div>
            <div class="upload" @click="$refs.driverPhoto.click()"></div>
          </div>
          <div v-else style="width: 100%; padding: 10px 0 0 0; text-align: center">
            <el-progress
              :width="160"
              type="circle"
              :stroke-width="10"
              :percentage="flag.riskProgress"
            ></el-progress>
          </div>
          <input
            multiple
            id="inp"
            ref="driverPhoto"
            type="file"
            style="display: none"
            @change="importDriverPhoto"
          />
        </div>
        <div slot="footer">
          <el-button
            type="primary"
            size="medium"
            @click="confirmImportDriverPhoto"
            :loading="uploading"
            :disabled="bulkImportList.length == 0 ? true : false"
          >
            {{ !uploading ? "上传车辆证照" : "上传中......" }}
          </el-button>
        </div>
      </el-dialog>
      <!-- 编辑司机图片dialog -->
      <el-dialog
        class="editDriverForm"
        :visible.sync="flag.editDriverPhoto"
        width="900px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <span slot="title" class="dialog-title"> 图片修改 </span>
        <el-form :model="editDriverForm" ref="editDriverForm" label-width="170px">
          <div class="content">
            <div class="upBox">
              <div>
                <UploadImg
                  ref="driverFace"
                  :loading="editDriverForm.driverFace.loading"
                  :uploaded="editDriverForm.driverFace.uploaded"
                  :picUrl="editDriverForm.driverFace.picUrl"
                  :isModel="false"
                  @update:file="updateFile"
                  :fileType="'Vehicle'"
                  @change="uploadDriverFace(editDriverForm.driverFace)"
                  @deleteImg="deleteImg(editDriverForm.driverFace)"
                >
                </UploadImg>
              </div>
              <div class="upText">
                <span class="required">*</span>
                车辆行驶证正页(正面)
                <i
                  @click="imgCase(14)"
                  class="el-icon-question"
                  style="cursor: pointer; color: rgb(230, 160, 62)"
                >
                  案例
                </i>
              </div>
            </div>
            <div class="upBox">
              <UploadImg
                ref="driveron"
                :loading="editDriverForm.driveron.loading"
                :uploaded="editDriverForm.driveron.uploaded"
                :picUrl="editDriverForm.driveron.picUrl"
                :isModel="false"
                @update:file="updateFile"
                :fileType="'Vehicle'"
                @change="uploadDriverBack(editDriverForm.driveron)"
                @deleteImg="deleteImg(editDriverForm.driveron)"
              >
              </UploadImg>
              <div class="upText">
                <span class="required">*</span>
                车辆行驶证副页(正面)
                <i
                  @click="imgCase(15)"
                  class="el-icon-question"
                  style="cursor: pointer; color: rgb(230, 160, 62)"
                >
                  案例
                </i>
              </div>
            </div>
            <div class="upBox">
              <UploadImg
                ref="driverBack"
                :loading="editDriverForm.driverBack.loading"
                :uploaded="editDriverForm.driverBack.uploaded"
                :picUrl="editDriverForm.driverBack.picUrl"
                :isModel="false"
                @update:file="updateFile"
                :fileType="'Vehicle'"
                @change="uploadDriverOn(editDriverForm.driverBack)"
                @deleteImg="deleteImg(editDriverForm.driverBack)"
              >
              </UploadImg>
              <div class="upText">
                车辆行驶证副页(反面)
                <i
                  @click="imgCase(16)"
                  class="el-icon-question"
                  style="cursor: pointer; color: rgb(230, 160, 62)"
                >
                  案例
                </i>
              </div>
            </div>
          </div>
          <div class="content">
            <div class="upBox">
              <UploadImg
                ref="convey"
                :loading="editDriverForm.conveyLicenceUrl.loading"
                :uploaded="editDriverForm.conveyLicenceUrl.uploaded"
                :picUrl="editDriverForm.conveyLicenceUrl.picUrl"
                :isModel="false"
                :fileType="'Vehicle'"
                @update:file="updateFile"
                @change="uploadConveyLicence(editDriverForm.conveyLicenceUrl)"
                @deleteImg="deleteImg(editDriverForm.conveyLicenceUrl)"
              >
              </UploadImg>
              <div class="upText">
                道路运输证
                <i
                  @click="imgCase(17)"
                  class="el-icon-question"
                  style="cursor: pointer; color: rgb(230, 160, 62)"
                >
                  案例
                </i>
              </div>
            </div>
            <div class="upBox">
              <UploadImg
                ref="elsePapers"
                :loading="editDriverForm.elsePapers.loading"
                :uploaded="editDriverForm.elsePapers.uploaded"
                :picUrl="editDriverForm.elsePapers.picUrl"
                :isModel="false"
                @update:file="updateFile"
                :fileType="'Vehicle'"
                @change="upelsePapers(editDriverForm.elsePapers)"
                @deleteImg="deleteImg(editDriverForm.elsePapers)"
              >
              </UploadImg>
              <div class="upText">附加证照</div>
            </div>
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="flag.editDriverPhoto = false">取 消</el-button>
          <el-button type="primary" @click="confirmEdit('editDriverForm')"
            >保存</el-button
          >
        </span>
      </el-dialog>
      <!-- 图片预览 -->
      <el-dialog :visible.sync="flag.showImg" title="图片预览">
        <el-image :src="currentImgUrl" :preview-src-list="[currentImgUrl]"></el-image>
      </el-dialog>
      <!-- 查看账户案例 -->
      <el-dialog
        class="addDialog"
        :visible.sync="flag.isCase"
        width="840px"
        :close-on-click-modal="false"
      >
        <span slot="title" class="dialog-title"> 查看案例 </span>
        <div v-for="(item, index) in caselist" :key="index">
          <img style="width: 800px" :src="item.SampleURL" />
          <div style="width: 100; text-align: center; font-size: 16px; color: #000">
            {{ item.Name }}
          </div>
        </div>
      </el-dialog>
      <!-- 批量上传 -->
      <el-dialog
        class="isBatch"
        :visible.sync="flag.isBatch"
        width="1310px"
        :close-on-click-modal="false"
      >
        <el-row slot="title">
          <el-col :span="10">
            <span class="dialog-title"> 批量上传 </span>
          </el-col>
          <el-col :span="12">
            <h1 style="color: red; font-size: 15px">
              导入运力超过100时,请先暂存再勾选导入！ 
            </h1>
          </el-col>
        </el-row>
        <div style="margin: 0 0 10px 0; display: flex">
          <div>
            <span style="padding: 0 10px">是否在运力池</span>
            <el-select
              v-model="batchListAttribute"
              placeholder="请选择"
              @change="batchListAttributeChange"
              style="margin-left: 10px; width: 130px"
              size="medium"
            >
              <el-option label="全部" value="0"></el-option>
              <el-option label="存在" value="1"></el-option>
              <el-option label="不存在" value="2"></el-option>
            </el-select>
            <span style="padding: 0 10px">是否我的运力</span>
            <el-select
              v-model="IsMyTC"
              placeholder="请选择"
              @change="isMyTCChange"
              style="margin: 0px 10px; width: 130px"
              size="medium"
            >
              <el-option label="全部" value="全部"></el-option>
              <el-option label="是" value="是"></el-option>
              <el-option label="否" value="否"></el-option>
            </el-select>
            <el-button
              type="primary"
              icon="el-icon-wallet"
              size="medium"
              @click="flag.showDriverInfo = !flag.showDriverInfo"
              >上传车辆表格</el-button
            >
            <el-button
              type="primary"
              :disabled="tableData.length == 0 ? true : false"
              icon="el-icon-picture-outline"
              size="medium"
              @click="flag.showDriverPhoto = !flag.showDriverPhoto"
              >上传车辆证照</el-button
            >
            <el-button
              type="primary"
              :disabled="tableData.length == 0 ? true : isSubmit"
              size="medium"
              @click="save('working', false)"
              icon="el-icon-upload"
              >暂存</el-button
            >
            <el-button
              type="primary"
              size="medium"
              :disabled="tableData.length == 0 ? true : isSubmit"
              @click="save('submit', false)"
              icon="el-icon-check"
              >提交资料(覆盖旧资料)</el-button
            >
            <el-button
              type="primary"
              size="medium"
              :disabled="tableData.length == 0 ? true : isSubmit"
              @click="save('submit', true)"
              icon="el-icon-check"
              >提交资料(引用运力池)</el-button
            >
          </div>
        </div>
        <div style="margin: 5px 0px">
          <el-button
            type="danger"
            size="medium"
            icon="el-icon-delete"
            @click="selectDeleteEvent()"
            >选中错误运力</el-button
          >
          <el-button
            type="danger"
            size="medium"
            icon="el-icon-delete"
            @click="deleteSelectEvent()"
            >删除选中</el-button
          >
        </div>
        <vxe-table
          :loading="loading"
          auto-resize
          border
          resizable
          show-header-overflow
          :data="tableData"
          :edit-config="{ trigger: 'click', mode: 'cell' }"
          height="620px"
          ref="xTable"
        >
          <vxe-column align="center" type="checkbox" width="50" fixed="left"></vxe-column>
          <vxe-column
            type="seq"
            align="center"
            width="50"
            title="序号"
            fixed="left"
          ></vxe-column>
          <vxe-table-column
            width="120"
            align="center"
            field="VehicleCarNumber"
            title="车牌号"
            :edit-render="{ name: 'input', autoselect: true }"
            fixed="left"
          >
            <template #edit="{ row }">
              <el-input
                v-model="row.VehicleCarNumber"
                type="text"
                @input="tableInp(row)"
              ></el-input>
            </template>
          </vxe-table-column>
          <vxe-table-column
            width="100"
            align="center"
            field="CarNumberColorName"
            title="车牌颜色"
          ></vxe-table-column>
          <vxe-table-column
            width="130"
            align="center"
            field="VehicleTypeName"
            :edit-render="{ name: 'input', autoselect: true }"
            title="车辆类型"
          ></vxe-table-column>
          <vxe-table-column
            width="100"
            align="center"
            field="VehicleLoad"
            title="载重(吨)"
            :edit-render="{ name: 'input', autoselect: true }"
          >
            <template #edit="{ row }">
              <el-input
                v-model="row.VehicleLoad"
                type="text"
                @input="tableInp(row)"
                onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
              >
              </el-input>
            </template>
          </vxe-table-column>
          <vxe-table-column
            width="100"
            align="center"
            field="VehicleLength"
            title="车长(米)"
            :edit-render="{ name: 'input', autoselect: true }"
          >
            <template #edit="{ row }">
              <el-input
                v-model="row.VehicleLength"
                type="text"
                @input="tableInp(row)"
                onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
              >
              </el-input>
            </template>
          </vxe-table-column>
          <vxe-table-column
            width="100"
            align="center"
            field="VehicleWidth"
            title="车宽(米)"
            :edit-render="{ name: 'input', autoselect: true }"
          >
            <template #edit="{ row }">
              <el-input
                v-model="row.VehicleWidth"
                type="text"
                @input="tableInp(row)"
                onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
              >
              </el-input>
            </template>
          </vxe-table-column>
          <vxe-table-column
            width="150"
            align="center"
            field="VehicleRTP"
            title="道路运输证号"
            :edit-render="{ name: 'input', autoselect: true }"
          ></vxe-table-column>
          <vxe-table-column
            width="150"
            align="center"
            field="VehicleMaster"
            title="所有人"
            :edit-render="{ name: 'input', autoselect: true }"
          ></vxe-table-column>
          <vxe-table-column
            width="150"
            align="center"
            field="VehicleLoadWeight"
            title="总重(吨)"
            :edit-render="{ name: 'input', autoselect: true }"
          ></vxe-table-column>
          <vxe-table-column
            width="150"
            align="center"
            field="VehicleLicesenAuthority"
            title="行驶证发证机关"
            :edit-render="{ name: 'input', autoselect: true }"
          ></vxe-table-column>
          <vxe-table-column
            width="100"
            align="center"
            :edit-render="{ name: 'input', autoselect: true }"
            field="VehiclePowerTypeName"
            title="能源类型"
          >
            <template #default="{ row }">
              <span>{{ row.VehiclePowerTypeName }}</span>
            </template>
            <template #edit="{ row }">
              <vxe-select
                v-model="row.VehiclePowerType"
                @change="vehiclePowerTypeChange(row)"
                clearable
              >
                <vxe-option
                  v-for="item in powerList"
                  :key="item.Code"
                  :value="item.Code"
                  :label="item.Name"
                ></vxe-option>
              </vxe-select>
            </template>
          </vxe-table-column>
          <vxe-table-column
            width="100"
            align="center"
            field="IsInTCName"
            title="平台运力池"
          >
            <template #default="{ row }">
              <el-tag :type="row.IsInTCName == '不存在' ? 'danger' : 'success'">
                {{ row.IsInTCName }}
              </el-tag>
            </template>
          </vxe-table-column>
          <vxe-table-column width="100" align="center" field="IsMyTC" title="我的运力">
            <template #default="{ row }">
              <el-tag :type="row.IsMyTC == '否' ? 'danger' : 'success'">
                {{ row.IsMyTC }}
              </el-tag>
            </template>
          </vxe-table-column>
          <vxe-table-column
            width="120"
            align="center"
            field="VehicleLoadPersons"
            title="核载人数"
            :edit-render="{ name: 'input', autoselect: true }"
          >
            <template #edit="{ row }">
              <el-input
                v-model="row.VehicleLoadPersons"
                type="text"
                @input="tableInp(row)"
              ></el-input>
            </template>
          </vxe-table-column>
          <vxe-table-column
            width="200"
            align="center"
            field="VehicleLicenseFrontPageURL"
            title="车辆行驶证正页(正面)"
          >
            <template #default="{ row }">
              <el-image
                v-if="row.VehicleLicenseFrontPageURL"
                style="width: 200; height: 80px"
                :src="row.VehicleLicenseFrontPageURL"
                :preview-src-list="[row.VehicleLicenseFrontPageURL]"
              >
              </el-image>
            </template>
          </vxe-table-column>
          <vxe-table-column
            width="200"
            align="center"
            field="VehicleLicenseSubPageOnURL"
            title="车辆行驶证副页(正面)"
          >
            <template #default="{ row }">
              <el-image
                v-if="row.VehicleLicenseSubPageOnURL"
                style="width: 200; height: 80px"
                :src="row.VehicleLicenseSubPageOnURL"
                :preview-src-list="[row.VehicleLicenseSubPageOnURL]"
              >
              </el-image>
            </template>
          </vxe-table-column>
          <vxe-table-column
            width="200"
            align="center"
            field="VehicleLicenseSubPageBackURL"
            title="车辆行驶证副页(反面)"
          >
            <template #default="{ row }">
              <el-image
                v-if="row.VehicleLicenseSubPageBackURL"
                style="width: 200; height: 80px"
                :src="row.VehicleLicenseSubPageBackURL"
                :preview-src-list="[row.VehicleLicenseSubPageBackURL]"
              >
              </el-image>
            </template>
          </vxe-table-column>
          <vxe-table-column
            width="200"
            align="center"
            field="VehicleRTPUTL"
            title="道路运输证"
          >
            <template #default="{ row }">
              <el-image
                v-if="row.VehicleRTPUTL"
                style="width: 200; height: 80px"
                :src="row.VehicleRTPUTL"
                :preview-src-list="[row.VehicleRTPUTL]"
              >
              </el-image>
            </template>
          </vxe-table-column>
          <vxe-table-column
            width="200"
            align="center"
            field="AdditionalLicenseURL"
            title="附加证照"
          >
            <template #default="{ row }">
              <el-image
                v-if="row.AdditionalLicenseURL"
                style="width: 200; height: 80px"
                :src="row.AdditionalLicenseURL"
                :preview-src-list="[row.AdditionalLicenseURL]"
              >
              </el-image>
            </template>
          </vxe-table-column>
          <vxe-table-column
            width="200"
            align="center"
            field="ErrorMsg"
            title="导入错误信息"
            fixed="right"
          >
            <template #default="{ row }">
              <div style="color: red" v-if="row.IsInTCName == '不存在'">
                {{
                  row.VehicleLicenseFrontPageURL === null
                    ? `${row.ErrorMsg}缺少证件图片`
                    : row.VehicleLicenseSubPageOnURL === null
                    ? `${row.ErrorMsg}缺少证件图片`
                    : row.ErrorMsg
                }}
              </div>
            </template>
          </vxe-table-column>
          <vxe-table-column
            width="200"
            align="center"
            field="Remark"
            title="备注"
            fixed="right"
          >
            <template #default="{ row }">
              <div style="color: red">{{ row.Remark }}</div>
            </template>
          </vxe-table-column>
          <vxe-table-column width="100" align="center" title="操作" fixed="right">
            <template #default="{ row }">
              <el-button
                @click="editDriverInfo(row)"
                type="primary"
                icon="el-icon-edit"
                size="small"
                title="编辑"
                >编辑
              </el-button>
            </template>
          </vxe-table-column>
        </vxe-table>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import UploadImg from "@/components/commonCmpt/cropperImage";
import { GetSampleInfo, getDataDict } from "@/api/common/common";
import { saveDraftVehicle, DeleteDraft } from "@/api/transport/vehicleManage/index";
import { VehicleExcel, vehiclesDraftInfo } from "@/api/transport/driverManage/bulkImport";
import {
  uploadVehicleFace,
  uploadVehicleBack,
  uploadVehiclePageOn,
  uploadVehicleRTP,
  deleteVehicles,
  PutCarIsTroubleBaseInfoAsync,
} from "@/api/transport/vehicleManage/verify";
import { GetFileInfo } from "@/api/common/common";
import { tCBaseInfo } from "@/api/transport/vehicleManage/index";
import { uploadOherCertificate } from "@/api/goodsOwner/goodsOwnerManage/authInfo";
import { upExport } from "@/api/finance/account/index";
import verify from "@/utils/verify";
import { GenNonDuplicateID } from "@/utils/utils";
import { mapGetters } from "vuex";
// 上传数组
let typeOne = [];
let typeTwo = [];
let typeThree = [];
let typeFour = [];
let typeFive = [];
// 定时器
let interval = "";
export default {
  data() {
    return {
      tableData: [], //表格数据
      flag: {
        //是否显示导入司机信息dialog
        showDriverInfo: false,
        //是否显示导入司机照片dialog
        showDriverPhoto: false,
        //是否显示编辑司机照片dialog
        editDriverPhoto: false,
        // 图片预览
        showImg: false,
        riskProgress: 0,
        // 案例
        isCase: false,
        // 批量上传
        isBatch: false,
      },
      //正在编辑的表格item的图片url
      currentEditItem: {
        DriverLicenceFace: "",
        DriverLicenceBack: "",
        DriverLicenceOn: "",
        conveyLicenceUrl: "",
        AdditionalLicenseURL: "",
      },
      bulkImportList: [], //批量导入的数据
      editDriverForm: {
        driveron: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        driverFace: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        driverBack: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        conveyLicenceUrl: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //其他证件
        elsePapers: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
      },
      currentImgUrl: "", //正在预览的图片
      // 是否正在上传
      uploading: false,
      // 是否显示加载框
      loading: false,
      // 上传完成的列表
      upSuccess: [],
      // 案例列表
      caselist: [],
      // 草稿箱列表
      batchData: [],
      batchDataLoading: false,
      //分页控件相关参数
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 是否可以提交
      isSubmit: false,
      // 搜索参数
      driverForm: {
        IsInTCCode: "",
        VehicleCarNumber: "",
      },
      // 运力池
      IsInTCList: [
        {
          Name: "全部",
          Code: "",
        },
        {
          Name: "不存在",
          Code: 0,
        },
        {
          Name: "存在",
          Code: 1,
        },
      ],
      powerList: [],
      IsMyTC: "全部", //是否是我的运力  是和否
      batchListAttribute: "0", //控制批量上传是否显示存在运力池 0全部 1存在 2不存在
      allTableData: [], //完整的tableData
      tableHeight: null,
    };
  },
  computed: {
    ...mapGetters(["getIsCarCaptain"]), // 如果是车队长,隐藏界面部分按钮
  },
  created() {
    this.vehiclesDraftInfo();
    // 能源类型
    getDataDict({ type: 10 }).then((res) => {
      this.powerList = res.patterSetInfo;
    });

    // 获取table表格高度
    this.getTableHeight();
  },
  methods: {
    //能源类型改变
    vehiclePowerTypeChange(e) {
      let needClear = true; //是否是清楚操作
      this.powerList.forEach((item) => {
        if (item.Code == e.VehiclePowerType) {
          needClear = false;
          e.VehiclePowerType = item.Code || "B";
          e.VehiclePowerTypeName = item.Name || "柴油";
        }
      });
      if (needClear) {
        e.VehiclePowerType = "B";
        e.VehiclePowerTypeName = "柴油";
      }
    },
    //是否我的运力筛选 this.batchListAttribute 0:全部 1：存在 2：不存在
    isMyTCChange(e) {
      if (e == "全部") {
        if (this.batchListAttribute == 0) {
          this.tableData = JSON.parse(JSON.stringify(this.allTableData));
        }
        if (this.batchListAttribute == 1) {
          this.tableData = JSON.parse(
            JSON.stringify(
              this.allTableData.filter(
                (item) => item.IsInTCName == "存在" || item.IsInTCName == "已存在"
              )
            )
          );
        }
        if (this.batchListAttribute == 2) {
          this.tableData = JSON.parse(
            JSON.stringify(
              this.allTableData.filter((item) => item.IsInTCName == "不存在")
            )
          );
        }
      }
      if (e == "是") {
        if (this.batchListAttribute == 0) {
          this.tableData = JSON.parse(
            JSON.stringify(this.allTableData.filter((item) => item.IsMyTC == "是"))
          );
        }
        if (this.batchListAttribute == 1) {
          this.tableData = JSON.parse(
            JSON.stringify(
              this.allTableData.filter(
                (item) =>
                  (item.IsInTCName == "存在" || item.IsInTCName == "已存在") &&
                  item.IsMyTC == "是"
              )
            )
          );
        }
        if (this.batchListAttribute == 2) {
          this.tableData = JSON.parse(
            JSON.stringify(
              this.allTableData.filter(
                (item) => item.IsInTCName == "不存在" && item.IsMyTC == "是"
              )
            )
          );
        }
      }
      if (e == "否") {
        if (this.batchListAttribute == 0) {
          this.tableData = JSON.parse(
            JSON.stringify(this.allTableData.filter((item) => item.IsMyTC == "否"))
          );
        }
        if (this.batchListAttribute == 1) {
          this.tableData = JSON.parse(
            JSON.stringify(
              this.allTableData.filter(
                (item) =>
                  (item.IsInTCName == "存在" || item.IsInTCName == "已存在") &&
                  item.IsMyTC == "否"
              )
            )
          );
        }
        if (this.batchListAttribute == 2) {
          this.tableData = JSON.parse(
            JSON.stringify(
              this.allTableData.filter(
                (item) => item.IsInTCName == "不存在" && item.IsMyTC == "否"
              )
            )
          );
        }
      }
    },
    //是否在运力池筛选
    batchListAttributeChange(e) {
      if (e == 0) {
        if (this.IsMyTC == "全部") {
          this.tableData = JSON.parse(JSON.stringify(this.allTableData));
        }
        if (this.IsMyTC == "是") {
          this.tableData = JSON.parse(
            JSON.stringify(this.allTableData.filter((item) => item.IsMyTC == "是"))
          );
        }
        if (this.IsMyTC == "否") {
          this.tableData = JSON.parse(
            JSON.stringify(this.allTableData.filter((item) => item.IsMyTC == "否"))
          );
        }
      }
      if (e == 1) {
        if (this.IsMyTC == "全部") {
          this.tableData = JSON.parse(
            JSON.stringify(
              this.allTableData.filter(
                (item) => item.IsInTCName == "存在" || item.IsInTCName == "已存在"
              )
            )
          );
        }
        if (this.IsMyTC == "是") {
          this.tableData = JSON.parse(
            JSON.stringify(
              this.allTableData.filter(
                (item) =>
                  (item.IsInTCName == "存在" || item.IsInTCName == "已存在") &&
                  item.IsMyTC == "是"
              )
            )
          );
        }
        if (this.IsMyTC == "否") {
          this.tableData = JSON.parse(
            JSON.stringify(
              this.allTableData.filter(
                (item) =>
                  (item.IsInTCName == "存在" || item.IsInTCName == "已存在") &&
                  item.IsMyTC == "否"
              )
            )
          );
        }
      }
      if (e == 2) {
        if (this.IsMyTC == "全部") {
          this.tableData = JSON.parse(
            JSON.stringify(
              this.allTableData.filter((item) => item.IsInTCName == "不存在")
            )
          );
        }
        if (this.IsMyTC == "是") {
          this.tableData = JSON.parse(
            JSON.stringify(
              this.allTableData.filter(
                (item) => item.IsInTCName == "不存在" && item.IsMyTC == "是"
              )
            )
          );
        }
        if (this.IsMyTC == "否") {
          this.tableData = JSON.parse(
            JSON.stringify(
              this.allTableData.filter(
                (item) => item.IsInTCName == "不存在" && item.IsMyTC == "否"
              )
            )
          );
        }
      }
    },
    //每页条数改变
    sizeChange(e) {
      this.pagination.pagesize = e;
      this.driversDraftInfo();
    },
    // 下载车辆表格
    downloadClick() {
      this.loading = true;
      let data = {
        fileType: "草稿箱车辆导出",
        fileName: "草稿箱车辆列表",
        IsInTCCode: this.driverForm.IsInTCCode,
        CarNumber: this.driverForm.VehicleCarNumber,
      };
      upExport({ Json: JSON.stringify(data) })
        .then((res) => {
          window.location.href = res.pathList[0].pathName;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 删除
    delClick() {
      let arr = [];
      this.tableData.forEach((item) => {
        let data = {
          VehicleID: item.VehicleID,
          VehicleCarNumber: item.VehicleCarNumber,
        };
        arr.push(data);
      });
      this.$confirm("此操作将永久删除选中的数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteVehicles(arr).then((res) => {
          this.tableData = [];
          this.vehiclesDraftInfo();
        });
      });
    },
    // 一键删除
    delClickAll() {
      this.$confirm("此操作将永久删除所有数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        DeleteDraft().then((res) => {
          this.batchData = [];
          this.vehiclesDraftInfo();
          this.$message.success("删除成功");
        });
      });
    },
    // 清空搜索
    resetForm() {
      this.driverForm = {
        IsInTCCode: "",
        VehicleCarNumber: "",
      };
      this.pagination.page = 1;
      this.vehiclesDraftInfo();
    },
    // 搜索
    search() {
      this.pagination.page = 1;
      this.vehiclesDraftInfo();
    },
    // 编辑表格
    tableInp(item) {
      this.$forceUpdate();
      for (let i in item) {
        let data = item[i];
        if (data == "") {
          if (i == "VehicleCarNumber") {
            this.isSubmit = true;
            return;
          } else {
            this.isSubmit = false;
          }
        }
      }
    },
    // 判断是否可以提交
    submitClick() {
      let arr = [];
      this.tableData.forEach((item) => {
        arr.push(item.flag);
      });
      this.isSubmit = arr.includes(false);
    },
    // 查看草稿箱车辆信息
    vehiclesDraftInfo() {
      this.batchDataLoading = true;
      let data = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        IsInTCCode: this.driverForm.IsInTCCode,
        VehicleCarNumber: this.driverForm.VehicleCarNumber,
      };
      vehiclesDraftInfo(data)
        .then((res) => {
          this.batchData = res.vehicles.map((item) => {
            if (item.SubmitDatetime) {
              item.SubmitDatetime = item.SubmitDatetime.slice(0, 10);
            }
            return item;
          });
          this.pagination.total = Number(res.totalRowCount);
        })
        .finally(() => {
          this.batchDataLoading = false;
        });
    },
    // 需要更改的数据
    selectChange(e) {
      this.tableData = e;
    },
    // 批量上传
    batchClick() {
      let arr = [];
      if (this.tableData.length != 0) {
        this.tableData.forEach((item) => {
          item.IsMyTC = "是";
          //获取能源类型的code  传给后端的
          this.powerList.forEach((el) => {
            if (el.Code == item.VehiclePowerType) {
              item.VehiclePowerTypeName = el.Name || "柴油";
              item.VehiclePowerType = el.Code || "B";
            }
          });
          if (item.IsInTCName == "不存在") {
            if (item.VehicleCarNumber == "") {
              item.flag = false;
            }
            arr.unshift(item);
          } else {
            arr.push(item);
          }
        });
      }
      this.tableData = arr.reverse();
      this.tableData = this.tableData.map((it) => {
        it.uid = GenNonDuplicateID();
        return it;
      });
      this.allTableData = JSON.parse(JSON.stringify(this.tableData));
      this.submitClick();
      this.flag.isBatch = true;
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e;
      this.vehiclesDraftInfo();
    },
    // 查看案例
    imgCase(SampleType) {
      this.caselist = [];
      GetSampleInfo({ SampleType }).then((res) => {
        this.caselist = res.data;
        this.flag.isCase = true;
      });
    },
    selectDeleteEvent() {
      let table = this.$refs.xTable;
      let removeList = [];
      this.tableData
        .filter((x) => x.IsInTCName   == "不存在")
        .forEach((row, inx) => {
          var ebro =
          row.VehicleLicenseFrontPageURL === null
                    ? `${row.ErrorMsg}缺少证件图片`
                    : row.VehicleLicenseSubPageOnURL === null
                    ? `${row.ErrorMsg}缺少证件图片`
                    : row.ErrorMsg
;
          if (ebro) {
            removeList.push(row);
          }
        });
      table.setCheckboxRow(removeList, true);
    },
    // 删除已选中的数据
    deleteSelectEvent() {
      this.$confirm("是否删除已选中的数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let table = this.$refs.xTable;
        let checkboxRecords = table.getCheckboxRecords();
        let removeList = [];
        checkboxRecords.forEach((checkbox) => {
          this.tableData.forEach((item, inx) => {
            if (item.uid == checkbox.uid) {
              this.tableData.splice(inx, 1);
              this.submitClick();
            }
          });
          this.allTableData.forEach((item, inx) => {
            if (item.uid == checkbox.uid) {
              this.allTableData.splice(inx, 1);
            }
          });

          removeList.push(checkbox.VehicleCarNumber);
        });

        // 从草稿箱移除的数据
        PutCarIsTroubleBaseInfoAsync(removeList).then((res) => {
          this.$message.success("删除成功");
        });
      });
    },
    //保存
    save(typeBtn, isQuoteTc) {
      this.loading = true;
      let params = {
        baseInfo: [],
        confirmInfo: [],
        type: typeBtn == "submit" ? 1 : 0,
        importType: 0, //0普通导入  1网络货运导入
        IsQuoteTc: isQuoteTc, //是否引用运力池资料
      };
      for (let i in this.tableData) {
        let item = this.tableData[i];
        item.Remark = "";
        if (typeBtn == "submit") {
          let isCheck = false;
          if (isQuoteTc) {
            if (item.IsInTCName == "不存在") {
              isCheck = true;
            }
          } else {
            isCheck = true;
          }
          if (isCheck) {
            if (item.VehicleCarNumber == null) {
              this.$message.error(`请输入第${Number(i) + 1}行中的车牌号`);
              this.loading = false;
              return;
            }
            if (item.VehicleLicenseFrontPageURL == null) {
              this.$message.error(`请上传第${Number(i) + 1}行中的车辆行驶证正页`);
              this.loading = false;
              return;
            }
            if (item.VehicleLicenseSubPageOnURL == null) {
              this.$message.error(`请上传第${Number(i) + 1}行中的车辆行驶证副页(正面)`);
              this.loading = false;
              return;
            }
            if (!item.CarNumberColor) {
              this.$message.error(`请选择第${Number(i) + 1}行中的车牌颜色`);
              this.loading = false;
              return;
            }
            if (!item.VehicleLoad) {
              this.$message.error(`请输入第${Number(i) + 1}行中的载重`);
              this.loading = false;
              return;
            }
            if (!item.VehicleLength) {
              this.$message.error(`请输入第${Number(i) + 1}行中的车长`);
              this.loading = false;
              return;
            }
            if (!item.VehicleTypeName) {
              this.$message.error(`请输入第${Number(i) + 1}行中的车辆类型`);
              this.loading = false;
              return;
            }
            if (!item.VehicleMaster) {
              this.$message.error(`请输入第${Number(i) + 1}行中的所有人`);
              this.loading = false;
              return;
            }
            if (!item.VehiclePowerType) {
              this.$message.error(`请选择第${Number(i) + 1}行中的能源类型`);
              this.loading = false;
              return;
            }
            if (!item.VehicleLoadWeight) {
              this.$message.error(`请输入第${Number(i) + 1}行中的总重(吨)`);
              this.loading = false;
              return;
            }
          }
        }
        //总质量25000KG为3轴，31000kg为4轴，牵引40000kg为3轴，25000kg以下均为2轴
        if (item.VehicleLoadWeight == 40 || item.VehicleLoadWeight == 25) {
          item.VehicleAxlesNumber = 3;
        } else if (item.VehicleLoadWeight == 31) {
          item.VehicleAxlesNumber = 4;
        } else if (item.VehicleLoadWeight < 25) {
          item.VehicleAxlesNumber = 2;
        }
        params.baseInfo.push({
          SerialNum: Number(i) + 1,
          vehicleID: "",
          VehicleCarNumber: item.VehicleCarNumber,
          CarNumberColor: item.CarNumberColor,
          VehicleTypeName: item.VehicleTypeName,
          VehicleRTP: item.VehicleRTP,
          VehicleLicenseArchives: item.VehicleLicenseArchives,
          VehicleLength: item.VehicleLength,
          VehicleWidth: item.VehicleWidth,
          VehicleLoad: item.VehicleLoad,
          VehicleMaster: item.VehicleMaster,
          VehicleUsage: item.VehicleUsage,
          VehicleVIN: item.VehicleVIN,
          PowerNumber: item.PowerNumber,
          VehicleLicenseRegDate: item.VehicleLicenseRegDate,
          VehicleLicenseAwardDate: item.VehicleLicenseAwardDate,
          VehicleHeight: item.VehicleHeight,
          VehicleLoadWeight: item.VehicleLoadWeight,
          VehicleTotalWeight: item.VehicleTotalWeight,
          VehicleLoadPersons: item.VehicleLoadPersons, // 核载人数
          VehiclePowerType: item.VehiclePowerType,
          VehicleLicenseValidDate: item.VehicleLicenseValidDate, //行驶证有效期
          VehicleLicesenAuthority: item.VehicleLicesenAuthority, //发证机关
          VehicleAttribution: item.VehicleAttribution, //归属类型
          BrandModelNumber: item.BrandModelNumber, //品牌型号
          VehicleBuyDate: item.VehicleBuyDate, //购车日期
          InspectAnnuallyDate: item.InspectAnnuallyDate, //年审日期
          BInsuranceDate: item.BInsuranceDate, //商业险到期日期
          CInsuranceDate: item.CInsuranceDate, //交强险到期日期
          VehicleAxlesNumber: item.VehicleAxlesNumber, //轴数
          Remark: item.ErrorMsg ? item.ErrorMsg : item.Remark, //错误原因
        });
        params.confirmInfo.push({
          UserId: 0,
          VehicleRTPUTL: item.VehicleRTPUTL,
          VehicleLicenseFrontPageURL: item.VehicleLicenseFrontPageURL, //机动车驾驶证（正页)
          VehicleLicenseSubPageOnURL: item.VehicleLicenseSubPageOnURL, //机动车驾驶证（副页)
          VehicleLicenseSubPageBackURL: item.VehicleLicenseSubPageBackURL, //道路运输从业资格证
          AdditionalLicenseURL: item.AdditionalLicenseURL,
        });
      }
      saveDraftVehicle(params)
        .then((res) => {
          // if (typeBtn == 'submit') {
          //   this.$router.go(-1)
          // } else {
          // 查看草稿箱司机信息
          this.vehiclesDraftInfo();
          this.flag.isBatch = false;
          // }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //设置当前组件正在操作的文件
    updateFile(file) {
      this.currentFile = file;
    },
    // 上传附件
    upelsePapers(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadOherCertificate(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          field.picUrl = res.ConsInfo;
        })
        .catch(() => {
          this.deleteImg(field);
        })
        .finally(() => {
          this.$refs.elsePapers.dialog();
        });
    },
    //上传驾驶证正面
    uploadDriverFace(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadVehicleFace(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          field.picUrl = res.vehicles.vehicleFaceUrl;
          this.editDriverForm.driverFace.picUrl = res.vehicles.vehicleFaceUrl;
          this.tableData[this.currentRow].VehicleCarNumber = res.vehicles.plate_num
            ? res.vehicles.plate_num
            : "";
          this.tableData[this.currentRow].VehicleTypeName = res.vehicles.vehicle_type
            ? res.vehicles.vehicle_type
            : "";
          this.tableData[this.currentRow].VehicleMaster = res.vehicles.owner
            ? res.vehicles.owner
            : "";
          this.tableData[this.currentRow].VehicleUsage = res.vehicles.use_character
            ? res.vehicles.use_character
            : "";
          this.tableData[this.currentRow].VehicleVIN = res.vehicles.vin
            ? res.vehicles.vin
            : "";
          this.tableData[this.currentRow].PowerNumber = res.vehicles.engine_num
            ? res.vehicles.engine_num
            : "";
          this.tableData[this.currentRow].BrandModelNumber = res.vehicles.model
            ? res.vehicles.model
            : "";
          this.tableData[this.currentRow].VehicleLicenseRegDate = res.vehicles
            .register_date
            ? this.insertStr(res.vehicles.register_date, 4, "-", 7, "-")
            : "";
          this.tableData[this.currentRow].VehicleLicenseAwardDate = res.vehicles
            .issue_date
            ? this.insertStr(res.vehicles.issue_date, 4, "-", 7, "-")
            : "";
          this.tableData[this.currentRow].VehicleLicesenAuthority = res.vehicles.Authority
            ? res.vehicles.Authority
            : "";
          this.tableData[this.currentRow].flag = true;
          if (this.tableData[this.currentRow].VehicleTypeName == "重型半挂牵引车") {
            this.tableData[this.currentRow].VehicleLength = 13.5;
          }
          //上传行驶证证照后---如车牌号在OCR识别后发生变化了---需要重新查新的车牌号是否在运力池
          if (this.tableData[this.currentRow].VehicleCarNumber) {
            tCBaseInfo({
              carNumber: this.tableData[this.currentRow].VehicleCarNumber,
            }).then((res) => {
              if (res.data.BaseInfo == null) {
                //没在运力池
                this.tableData[this.currentRow].IsInTCName = "不存在";
              } else {
                //存在运力池
                this.tableData[this.currentRow].IsInTCName = "已存在";
              }
            });
          }
        })
        .catch(() => {
          // this.deleteImg(field)
        })
        .finally(() => {
          this.$refs.driverFace.dialog();
          this.submitClick();
        });
    },
    //上传驾驶证副页正面
    uploadDriverBack(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadVehicleBack(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          field.picUrl = res.vehicles.vehicleBackUrl;
          this.tableData[this.currentRow].VehicleLoad = res.vehicles.approved_load
            ? Number(res.vehicles.approved_load.split("kg")[0]) / 1000
            : "";
          this.tableData[this.currentRow].VehicleLength =
            this.tableData[this.currentRow].VehicleTypeName == "重型半挂牵引车"
              ? 13.5
              : res.vehicles.overall_dimension
              ? Number(res.vehicles.overall_dimension.split("X")[0]) / 1000
              : "";
          this.tableData[this.currentRow].VehicleWidth = res.vehicles.overall_dimension
            ? Number(res.vehicles.overall_dimension.split("X")[1]) / 1000
            : "";
          this.tableData[this.currentRow].VehicleLicenseArchives = res.vehicles.file_no
            ? res.vehicles.file_no
            : "";
          this.tableData[this.currentRow].VehicleHeight = res.vehicles.overall_dimension
            ? Number(
                res.vehicles.overall_dimension
                  .split("X")[2]
                  .slice(0, res.vehicles.overall_dimension.split("X")[2].length - 2)
              ) / 1000
            : "";
          this.tableData[this.currentRow].VehicleLoadWeight = res.vehicles.gross_mass
            ? Number(res.vehicles.gross_mass.split("kg")[0]) / 1000
            : "";
          this.tableData[this.currentRow].VehicleTotalWeight = res.vehicles.unladen_mass
            ? Number(res.vehicles.unladen_mass.split("kg")[0]) / 1000
            : "";
          if (res.vehicles.appproved_passenger_capacity) {
            let str = res.vehicles.appproved_passenger_capacity;
            if (verify._isDecimals(str) == false) {
              this.tableData[this.currentRow].VehicleLoadPersons = str;
            } else {
              this.tableData[this.currentRow].VehicleLoadPersons = "";
            }
          } else {
            this.tableData[this.currentRow].VehicleLoadPersons = "";
          }
          if (res.vehicles.energyType) {
            this.powerList.forEach((item) => {
              if (item.Name == res.vehicles.energyType) {
                this.tableData[this.currentRow].VehiclePowerType = item.Code || "B";
                this.tableData[this.currentRow].VehiclePowerTypeName =
                  item.Name || "柴油";
              }
            });
          }
          this.tableData[this.currentRow].flag = true;
        })
        .catch(() => {
          // this.deleteImg(field)
        })
        .finally(() => {
          this.$refs.driveron.dialog();
          this.submitClick();
        });
    },
    //识别日期插入-
    insertStr(soure, firstPosition, firstStr, secondPosition, secondStr) {
      soure = soure.slice(0, firstPosition) + firstStr + soure.slice(firstPosition);
      return soure.slice(0, secondPosition) + secondStr + soure.slice(secondPosition);
    },
    // 上传驾驶证副页反面
    uploadDriverOn(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadVehiclePageOn(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          field.picUrl = res.vehicles;
        })
        .catch(() => {
          this.deleteImg(field);
        })
        .finally(() => {
          this.$refs.driverBack.dialog();
        });
    },
    //上传道路运输从业资格证
    uploadConveyLicence(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadVehicleRTP(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          let obj = res.data ? res.data : {};
          field.picUrl = obj.ImgUrl;
          this.editDriverForm.conveyLicenceUrl.picUrl = obj.ImgUrl;
          this.tableData[this.currentRow].VehicleRTP = obj.Detail
            ? obj.Detail.SerialNumber
            : "";
        })
        .catch(() => {
          this.deleteImg(field);
        })
        .finally(() => {
          this.$refs.convey.dialog();
        });
    },
    //导入司机证照
    importDriverPhoto(e) {
      let file = e.srcElement.files || e.target.files;
      if (file.length > 100) {
        this.$message.error(`批量上传最多支持100张`);
        return;
      } else {
        if (this.bulkImportList.length > 100) {
          this.$message.error(`批量上传已上限`);
          return;
        }
      }
      for (let i = 0; i < file.length; i++) {
        let item = file[i];
        // 限制最大上传
        if (item.size > 8 * 1024 * 1024) {
          this.$message({
            message: `文件名：${item.name}的图片大于 8MB， 请上传小于 8MB 的图片`,
            type: "error",
            duration: 5000,
          });
          document.getElementById("inp").value = "";
        } else {
          //获取文件名判断是否符合命名规范
          let name = item.name;
          // 车牌正则
          let reg = "";
          if (name.substr(0, name.indexOf("-")).length == 7) {
            // 燃油车牌
            reg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/;
          } else {
            // 新能源车牌
            reg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{5}[A-Z0-9挂学警港澳]{1}$/;
          }
          // 上传图片
          if (reg.test(name.substr(0, name.indexOf("-"))) == false) {
            this.$message.error(`文件名：${item.name}的图片命名不符合规范`);
            document.getElementById("inp").value = "";
          } else {
            // 获取文件名称前缀
            let phone = name.substr(0, name.indexOf("-"));
            this.tableData.forEach((el) => {
              if (phone == el.VehicleCarNumber) {
                let data = {
                  url: window.URL.createObjectURL(item),
                  name: item.name,
                  size: item.size,
                  file: item,
                };
                this.bulkImportList.push(data);
              }
            });
          }
        }
      }
    },
    //把导入的司机证件照插入到表格中
    confirmImportDriverPhoto() {
      this.uploading = true;
      this.flag.riskProgress = 0;
      typeOne = [];
      typeTwo = [];
      typeThree = [];
      typeFour = [];
      typeFive = [];
      this.bulkImportList.forEach((item, index) => {
        // 获取文件名
        let name = item.name;
        //获取图片的类型 1.行驶证正页 2.行驶证副页（正）3.行驶证副页（反） 4.道路运输经营许可证
        let type = name.substring(name.indexOf(".") - 1, name.indexOf("."));
        // 根据不同的 type 值进行添加数据组
        if (type == 1) {
          typeOne.push(item);
        } else if (type == 2) {
          typeTwo.push(item);
        } else if (type == 3) {
          typeThree.push(item);
        } else if (type == 4) {
          typeFour.push(item);
        } else if (type == 5) {
          typeFive.push(item);
        }
      });
      if (typeOne.length != 0) {
        this.recursion(typeOne, 1);
      } else {
        if (typeTwo.length != 0) {
          this.recursion(typeTwo, 2);
        } else {
          if (typeThree.length != 0) {
            this.recursion(typeThree, 3);
          } else {
            if (typeFour.length != 0) {
              this.recursion(typeFour, 4);
            } else {
              this.recursion(typeFive, 5);
            }
          }
        }
      }
      // 获取上传总长度
      let bulkInx = this.bulkImportList.length;
      // 定时器
      interval = setInterval(() => {
        // 获取正在传长度
        let upInx = this.upSuccess.length;
        if (this.flag.riskProgress >= 100) {
          this.flag.riskProgress = 100;
          clearInterval(interval);
          this.bulkImportList = [];
          this.uploading = false;
          this.flag.showDriverPhoto = false;
          this.flag.riskProgress = 0;
        } else {
          this.flag.riskProgress = Math.round((upInx / bulkInx) * 100);
        }
      }, 500);
    },
    /*
     * 列表分批次上传
     * listDat：数组列表
     * type：上传类型 1.行驶证正页 2.行驶证副页（正）3.行驶证副页（反） 4.道路运输经营许可证 5.附加证照
     */
    recursion(listDat, type) {
      // 外层数组
      let arr = [];
      // 外层数组里面的子数组
      let arrInx = 0;
      // 子数组多少条数据
      let num = 20;
      // 循环有多少个二维数组
      listDat.forEach((listData, listInx) => {
        if (Number(listInx + 1) % num == 0) {
          arrInx++;
        }
      });
      // 循环二维数组长度创建二维数组
      for (let i = 0; i <= arrInx; i++) {
        arr[i] = [];
      }
      // 二维数组添加数据
      listDat.forEach((listData, listInx) => {
        arr[Math.floor(listInx / num)].push(listData);
      });
      // 循环最大数组获取每个子数组的长度及索引
      let arrlist = [];
      arr.forEach((arrItem, arrInx) => {
        let data = {
          length: arrItem.length,
          index: arrInx,
        };
        arrlist.push(data);
      });
      // 循环第一个子数组
      let arrIndex = arr[0].length;
      arr[0].forEach((item, itemInx) => {
        let fromData = new FormData();
        fromData.append("file", item.file);
        fromData.append("phone", item.name.substr(0, item.name.indexOf("-")));
        // 行驶证正页
        if (type == 1) {
          // 行驶证正页
          uploadVehicleFace(fromData)
            .then((res) => {
              this.currentEditItem.DriverLicenceFace = res.vehicles.vehicleFaceUrl;
              this.upSuccess.push(res.vehicles.vehicleFaceUrl);
              document.getElementById("inp").value = "";
              this.tableData.forEach((el) => {
                if (res.phone == el.VehicleCarNumber) {
                  el.VehicleLicenseFrontPageURL = res.vehicles.vehicleFaceUrl;
                  el.VehicleCarNumber = res.vehicles.plate_num
                    ? res.vehicles.plate_num
                    : "";
                  el.VehicleTypeName = res.vehicles.vehicle_type;
                  el.VehicleMaster = res.vehicles.owner ? res.vehicles.owner : "";
                  el.VehicleUsage = res.vehicles.use_character
                    ? res.vehicles.use_character
                    : "";
                  el.VehicleVIN = res.vehicles.vin ? res.vehicles.vin : "";
                  el.PowerNumber = res.vehicles.engine_num ? res.vehicles.engine_num : "";
                  el.BrandModelNumber = res.vehicles.model ? res.vehicles.model : "";
                  el.VehicleLicenseRegDate = res.vehicles.register_date
                    ? this.insertStr(res.vehicles.register_date, 4, "-", 7, "-")
                    : "";
                  el.VehicleLicenseAwardDate = res.vehicles.issue_date
                    ? this.insertStr(res.vehicles.issue_date, 4, "-", 7, "-")
                    : "";
                  el.VehicleLicesenAuthority = res.vehicles.Authority
                    ? res.vehicles.Authority
                    : "";
                  el.flag = true;
                  if (el.VehicleTypeName == "重型半挂牵引车") {
                    el.VehicleLength = 13.5;
                  }
                  this.submitClick();
                }
              });
            })
            .finally(() => {
              if (Number(itemInx + 1) == arrIndex) {
                // 改变 this 指向
                let self = this;
                // 递归函数
                function sum(index) {
                  if (arr[index] == undefined) {
                    this.recursion(typeTwo, 2);
                    return;
                  }
                  let sumInx = arr[index].length;
                  if (sumInx != 0) {
                    // 循环当前数组列表
                    arr[index].forEach((dataItem, dataInx) => {
                      let dataFram = new FormData();
                      dataFram.append("file", dataItem.file);
                      dataFram.append(
                        "phone",
                        dataItem.name.substr(0, dataItem.name.indexOf("-"))
                      );
                      // 行驶证正页
                      uploadVehicleFace(dataFram)
                        .then((res) => {
                          this.currentEditItem.DriverLicenceFace =
                            res.vehicles.vehicleFaceUrl;
                          this.upSuccess.push(res.vehicles.vehicleFaceUrl);
                          document.getElementById("inp").value = "";
                          this.tableData.forEach((el) => {
                            if (res.phone == el.VehicleCarNumber) {
                              el.VehicleLicenseFrontPageURL = res.vehicles.vehicleFaceUrl;
                              el.VehicleCarNumber = res.vehicles.plate_num
                                ? res.vehicles.plate_num
                                : "";
                              el.VehicleTypeName = res.vehicles.vehicle_type;
                              el.VehicleMaster = res.vehicles.owner
                                ? res.vehicles.owner
                                : "";
                              el.VehicleUsage = res.vehicles.use_character
                                ? res.vehicles.use_character
                                : "";
                              el.VehicleVIN = res.vehicles.vin ? res.vehicles.vin : "";
                              el.PowerNumber = res.vehicles.engine_num
                                ? res.vehicles.engine_num
                                : "";
                              el.BrandModelNumber = res.vehicles.model
                                ? res.vehicles.model
                                : "";
                              el.VehicleLicenseRegDate = res.vehicles.register_date
                                ? this.insertStr(
                                    res.vehicles.register_date,
                                    4,
                                    "-",
                                    7,
                                    "-"
                                  )
                                : "";
                              el.VehicleLicenseAwardDate = res.vehicles.issue_date
                                ? this.insertStr(res.vehicles.issue_date, 4, "-", 7, "-")
                                : "";
                              el.VehicleLicesenAuthority = res.vehicles.Authority
                                ? res.vehicles.Authority
                                : "";
                              el.flag = true;
                              if (el.VehicleTypeName == "重型半挂牵引车") {
                                el.VehicleLength = 13.5;
                              }
                              this.submitClick();
                            }
                          });
                        })
                        .finally(() => {
                          if (Number(dataInx + 1) == sumInx) {
                            index++;
                            sum.call(self, index);
                          }
                        });
                    });
                  } else {
                    this.recursion(typeTwo, 2);
                  }
                }
                // 调用递归
                sum.call(self, 1);
              }
            });
        }
        // 行驶证副页（正）
        if (type == 2) {
          // 行驶证副页正面
          uploadVehicleBack(fromData)
            .then((res) => {
              this.currentEditItem.DriverLicenceBack = res.vehicles.vehicleBackUrl;
              this.upSuccess.push(res.vehicles.vehicleBackUrl);
              document.getElementById("inp").value = "";
              this.tableData.forEach((el) => {
                if (res.phone == el.VehicleCarNumber) {
                  el.VehicleLicenseSubPageOnURL = res.vehicles.vehicleBackUrl;
                  el.VehicleLoad =
                    Number(res.vehicles.approved_load.split("kg")[0]) / 1000;
                  el.VehicleLength =
                    el.VehicleTypeName == "重型半挂牵引车"
                      ? 13.5
                      : Number(res.vehicles.overall_dimension.split("X")[0]) / 1000;
                  el.VehicleWidth =
                    Number(res.vehicles.overall_dimension.split("X")[1]) / 1000;
                  el.VehicleLicenseArchives = res.vehicles.file_no
                    ? res.vehicles.file_no
                    : "";
                  el.VehicleHeight = res.vehicles.overall_dimension
                    ? Number(
                        res.vehicles.overall_dimension
                          .split("X")[2]
                          .slice(
                            0,
                            res.vehicles.overall_dimension.split("X")[2].length - 2
                          )
                      ) / 1000
                    : "";
                  el.VehicleLoadWeight = res.vehicles.gross_mass
                    ? Number(res.vehicles.gross_mass.split("kg")[0]) / 1000
                    : "";
                  el.VehicleTotalWeight = res.vehicles.unladen_mass
                    ? Number(res.vehicles.unladen_mass.split("kg")[0]) / 1000
                    : "";
                  if (res.vehicles.appproved_passenger_capacity) {
                    let str = res.vehicles.appproved_passenger_capacity;
                    if (verify._isDecimals(str) == false) {
                      el.VehicleLoadPersons = str;
                    } else {
                      el.VehicleLoadPersons = "";
                    }
                  } else {
                    el.VehicleLoadPersons = "";
                  }
                  el.flag = true;
                  if (res.vehicles.energyType) {
                    this.powerList.forEach((item) => {
                      if (item.Name == res.vehicles.energyType) {
                        el.VehiclePowerType = item.Code || "B";
                        el.VehiclePowerTypeName = item.Name || "柴油";
                      }
                    });
                  }
                  this.submitClick();
                }
              });
            })
            .finally(() => {
              if (Number(itemInx + 1) == arrIndex) {
                // 改变 this 指向
                let self = this;
                // 递归函数
                function sum(index) {
                  if (arr[index] == undefined) {
                    if (typeThree.length == 0) {
                      if (typeFour.length != 0) {
                        this.recursion(typeFour, 4);
                      } else {
                        this.recursion(typeFive, 5);
                      }
                    } else {
                      this.recursion(typeThree, 3);
                    }
                    return;
                  }
                  let sumInx = arr[index].length;
                  if (sumInx != 0) {
                    // 循环当前数组列表
                    arr[index].forEach((dataItem, dataInx) => {
                      let dataFram = new FormData();
                      dataFram.append("file", dataItem.file);
                      dataFram.append(
                        "phone",
                        dataItem.name.substr(0, dataItem.name.indexOf("-"))
                      );
                      // 行驶证副页正面
                      uploadVehicleBack(dataFram)
                        .then((res) => {
                          this.currentEditItem.DriverLicenceBack =
                            res.vehicles.vehicleBackUrl;
                          this.upSuccess.push(res.vehicles.vehicleBackUrl);
                          document.getElementById("inp").value = "";
                          this.tableData.forEach((el) => {
                            if (res.phone == el.VehicleCarNumber) {
                              el.VehicleLicenseSubPageOnURL = res.vehicles.vehicleBackUrl;
                              el.VehicleLoad =
                                Number(res.vehicles.approved_load.split("kg")[0]) / 1000;
                              el.VehicleLength =
                                el.VehicleTypeName == "重型半挂牵引车"
                                  ? 13.5
                                  : Number(res.vehicles.overall_dimension.split("X")[0]) /
                                    1000;
                              el.VehicleWidth =
                                Number(res.vehicles.overall_dimension.split("X")[1]) /
                                1000;
                              el.VehicleLicenseArchives = res.vehicles.file_no
                                ? res.vehicles.file_no
                                : "";
                              el.VehicleHeight = res.vehicles.overall_dimension
                                ? Number(
                                    res.vehicles.overall_dimension
                                      .split("X")[2]
                                      .slice(
                                        0,
                                        res.vehicles.overall_dimension.split("X")[2]
                                          .length - 2
                                      )
                                  ) / 1000
                                : "";
                              el.VehicleLoadWeight = res.vehicles.gross_mass
                                ? Number(res.vehicles.gross_mass.split("kg")[0]) / 1000
                                : "";
                              el.VehicleTotalWeight = res.vehicles.unladen_mass
                                ? Number(res.vehicles.unladen_mass.split("kg")[0]) / 1000
                                : "";
                              if (res.vehicles.appproved_passenger_capacity) {
                                let str = res.vehicles.appproved_passenger_capacity;
                                if (verify._isDecimals(str) == false) {
                                  el.VehicleLoadPersons = str;
                                } else {
                                  el.VehicleLoadPersons = "";
                                }
                              } else {
                                el.VehicleLoadPersons = "";
                              }
                              if (res.vehicles.energyType) {
                                this.powerList.forEach((item) => {
                                  if (item.Name == res.vehicles.energyType) {
                                    el.VehiclePowerType = item.Code || "B";
                                    el.VehiclePowerTypeName = item.Name || "柴油";
                                  }
                                });
                              }
                              el.flag = true;
                              this.submitClick();
                            }
                          });
                        })
                        .finally(() => {
                          if (Number(dataInx + 1) == sumInx) {
                            index++;
                            sum.call(self, index);
                          }
                        });
                    });
                  } else {
                    if (typeThree.length == 0) {
                      if (typeFour.length != 0) {
                        this.recursion(typeFour, 4);
                      } else {
                        this.recursion(typeFive, 5);
                      }
                    } else {
                      this.recursion(typeThree, 3);
                    }
                  }
                }
                // 调用递归
                sum.call(self, 1);
              }
            });
        }
        // 行驶证副页（反）
        if (type == 3) {
          // 行驶证副页反面
          uploadVehiclePageOn(fromData)
            .then((res) => {
              this.currentEditItem.DriverLicenceOn = res.vehicles;
              this.upSuccess.push(res.vehicles);
              document.getElementById("inp").value = "";
              this.tableData.forEach((el) => {
                if (res.phone == el.VehicleCarNumber) {
                  el.VehicleLicenseSubPageBackURL = res.vehicles;
                }
              });
            })
            .finally(() => {
              if (Number(itemInx + 1) == arrIndex) {
                // 改变 this 指向
                let self = this;
                // 递归函数
                function sum(index) {
                  if (arr[index] == undefined) {
                    if (typeFour.length != 0) {
                      this.recursion(typeFour, 4);
                    } else {
                      this.recursion(typeFive, 5);
                    }
                    return;
                  }
                  let sumInx = arr[index].length;
                  if (sumInx != 0) {
                    // 循环当前数组列表
                    arr[index].forEach((dataItem, dataInx) => {
                      let dataFram = new FormData();
                      dataFram.append("file", dataItem.file);
                      dataFram.append(
                        "phone",
                        dataItem.name.substr(0, dataItem.name.indexOf("-"))
                      );
                      uploadVehiclePageOn(dataFram)
                        .then((res) => {
                          this.currentEditItem.DriverLicenceOn = res.vehicles;
                          this.upSuccess.push(res.vehicles);
                          document.getElementById("inp").value = "";
                          this.tableData.forEach((el) => {
                            if (res.phone == el.VehicleCarNumber) {
                              el.VehicleLicenseSubPageBackURL = res.vehicles;
                            }
                          });
                        })
                        .finally(() => {
                          if (Number(dataInx + 1) == sumInx) {
                            index++;
                            sum.call(self, index);
                          }
                        });
                    });
                  } else {
                    if (typeFour.length != 0) {
                      this.recursion(typeFour, 4);
                    } else {
                      this.recursion(typeFive, 5);
                    }
                  }
                }
                // 调用递归
                sum.call(self, 1);
              }
            });
        }
        // 道路运输经营许可证
        if (type == 4) {
          // 道路经营许可证
          uploadVehicleRTP(fromData)
            .then((res) => {
              let obj = res.data ? res.data : {};
              this.currentEditItem.conveyLicenceUrl = obj.ImgUrl;
              this.upSuccess.push(obj.ImgUrl);
              document.getElementById("inp").value = "";
              this.tableData.forEach((el) => {
                if (res.phone == el.VehicleCarNumber) {
                  el.VehicleRTPUTL = obj.ImgUrl;
                  el.VehicleRTP = obj.Detail ? obj.Detail.SerialNumber : "";
                }
              });
            })
            .finally(() => {
              if (Number(itemInx + 1) == arrIndex) {
                // 改变 this 指向
                let self = this;
                // 递归函数
                function sum(index) {
                  if (arr[index] == undefined) {
                    if (typeFive.length == 0) {
                      clearInterval(interval);
                      this.bulkImportList = [];
                      this.uploading = false;
                      this.flag.showDriverPhoto = false;
                    } else {
                      this.recursion(typeFive, 5);
                    }
                    return;
                  }
                  let sumInx = arr[index].length;
                  if (sumInx != 0) {
                    // 循环当前数组列表
                    arr[index].forEach((dataItem, dataInx) => {
                      let dataFram = new FormData();
                      dataFram.append("file", dataItem.file);
                      dataFram.append(
                        "phone",
                        dataItem.name.substr(0, dataItem.name.indexOf("-"))
                      );
                      uploadVehicleRTP(dataFram)
                        .then((res) => {
                          let obj = res.data ? res.data : {};
                          this.currentEditItem.conveyLicenceUrl = obj.ImgUrl;
                          this.upSuccess.push(obj.ImgUrl);
                          document.getElementById("inp").value = "";
                          this.tableData.forEach((el) => {
                            if (res.phone == el.VehicleCarNumber) {
                              el.VehicleRTPUTL = obj.ImgUrl;
                              el.VehicleRTP = obj.Detail ? obj.Detail.SerialNumber : "";
                            }
                          });
                        })
                        .finally(() => {
                          if (Number(dataInx + 1) == sumInx) {
                            index++;
                            sum.call(self, index);
                          }
                        });
                    });
                  }
                }
                // 调用递归
                sum.call(self, 1);
              } else {
                if (typeFour.length != 0) {
                  this.recursion(typeFive, 5);
                }
              }
            });
        }
        // 附加证件
        if (type == 5) {
          // 道路经营许可证
          uploadOherCertificate(fromData)
            .then((res) => {
              this.currentEditItem.AdditionalLicenseURL = res.ConsInfo;
              this.upSuccess.push(res.ConsInfo);
              document.getElementById("inp").value = "";
              this.tableData.forEach((el) => {
                if (res.phone == el.VehicleCarNumber) {
                  el.AdditionalLicenseURL = res.ConsInfo;
                }
              });
            })
            .finally(() => {
              if (Number(itemInx + 1) == arrIndex) {
                // 改变 this 指向
                let self = this;
                // 递归函数
                function sum(index) {
                  if (arr[index] == undefined) {
                    clearInterval(interval);
                    this.bulkImportList = [];
                    this.uploading = false;
                    this.flag.showDriverPhoto = false;
                    return;
                  }
                  let sumInx = arr[index].length;
                  if (sumInx != 0) {
                    // 循环当前数组列表
                    arr[index].forEach((dataItem, dataInx) => {
                      let dataFram = new FormData();
                      dataFram.append("file", dataItem.file);
                      dataFram.append(
                        "phone",
                        dataItem.name.substr(0, dataItem.name.indexOf("-"))
                      );
                      uploadOherCertificate(dataFram)
                        .then((res) => {
                          this.currentEditItem.AdditionalLicenseURL = res.ConsInfo;
                          this.upSuccess.push(res.ConsInfo);
                          document.getElementById("inp").value = "";
                          this.tableData.forEach((el) => {
                            if (res.phone == el.VehicleCarNumber) {
                              el.AdditionalLicenseURL = res.ConsInfo;
                            }
                          });
                        })
                        .finally(() => {
                          if (Number(dataInx + 1) == sumInx) {
                            index++;
                            sum.call(self, index);
                          }
                        });
                    });
                  }
                }
                // 调用递归
                sum.call(self, 1);
              }
            });
        }
      });
    },
    //上传Excel
    VehicleExcel() {
      this.$refs.driverInfo.click();
      this.$refs.driverInfo.value = "";
    },
    //导入车辆信息
    importDriverInfo(e) {
      this.loading = true;
      this.flag.showDriverInfo = false;
      const file = e.srcElement.files[0] || e.target.files[0];
      let formData = new FormData();
      formData.append("file", file);
      formData.append("SerialNum", this.tableData.length);
      VehicleExcel(formData)
        .then((res) => {
          let arr = [];
          for (let i in res.all.data) {
            let item = res.all.data[i];
            item.index = i;
            //总质量25000KG为3轴，31000kg为4轴，牵引40000kg为3轴，25000kg以下均为2轴
            if (item.VehicleLoadWeight == 40 || item.VehicleLoadWeight == 25) {
              item.VehicleAxlesNumber = 3;
            } else if (item.VehicleLoadWeight == 31) {
              item.VehicleAxlesNumber = 4;
            } else if (item.VehicleLoadWeight < 25) {
              item.VehicleAxlesNumber = 2;
            }
            //获取能源类型的code  传给后端的
            this.powerList.forEach((el) => {
              if (el.Code == item.VehiclePowerType) {
                item.VehiclePowerTypeName = el.Name || "柴油";
                item.VehiclePowerType = el.Code || "B";
              } else {
                item.VehiclePowerTypeName = "柴油";
                item.VehiclePowerType = "B";
              }
            });
            arr.push(item.flag);
            this.tableData.push(item);
          }
          this.tableData = this.tableData.map((it) => {
            it.uid = GenNonDuplicateID();
            return it;
          });
          this.allTableData = JSON.parse(JSON.stringify(this.tableData));
          this.isSubmit = arr.includes(false);
        })
        .finally(() => {
          this.loading = false;
          this.$refs.driverInfo.value = "";
        });
    },
    //表格item编辑保存
    confirmEdit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //把保存的值放到表格里面去
          this.tableData[this.currentRow].VehicleLicenseFrontPageURL = this.editDriverForm
            .driverFace.picUrl
            ? this.editDriverForm.driverFace.picUrl
            : null;
          this.tableData[this.currentRow].VehicleLicenseSubPageOnURL = this.editDriverForm
            .driveron.picUrl
            ? this.editDriverForm.driveron.picUrl
            : null;
          this.tableData[this.currentRow].VehicleLicenseSubPageBackURL = this
            .editDriverForm.driverBack.picUrl
            ? this.editDriverForm.driverBack.picUrl
            : null;
          this.tableData[this.currentRow].VehicleRTPUTL = this.editDriverForm
            .conveyLicenceUrl.picUrl
            ? this.editDriverForm.conveyLicenceUrl.picUrl
            : null;
          this.tableData[this.currentRow].AdditionalLicenseURL = this.editDriverForm
            .elsePapers.picUrl
            ? this.editDriverForm.elsePapers.picUrl
            : null;
          this.flag.editDriverPhoto = false;
        }
      });
    },
    //下载Excel模板
    download() {
      GetFileInfo({ ftType: 1 }).then((res) => {
        if (res.data.FTURL == "") {
          this.$message.error("暂未找到该模板，请稍后下载...");
        } else {
          window.location.href = res.data.FTURL;
        }
      });
    },
    // 图片预览
    previewImg(url) {
      if (url) {
        this.currentImgUrl = url;
        this.flag.showImg = !this.flag.showImg;
      } else {
        this.$message.error("请先上传相关证件照片");
      }
    },
    //编辑司机信息
    editDriverInfo(item) {
      //判断是否有多条相同的车牌号
      let hasSame = false;
      let sameObj = null;
      this.tableData.forEach((el1) => {
        if (sameObj) return;
        this.tableData.forEach((el2) => {
          if (sameObj) return;
          if (el1.VehicleCarNumber === el2.VehicleCarNumber && el1.index !== el2.index) {
            sameObj = el1;
            hasSame = true;
          }
        });
      });
      if (hasSame && item.VehicleCarNumber === sameObj.VehicleCarNumber) {
        this.$message.error(
          `该车牌${sameObj.VehicleCarNumber}与表格中有重复信息，请删除重复的信息！`
        );
        return;
      }

      this.tableData.forEach((data, index) => {
        if (data.VehicleCarNumber == item.VehicleCarNumber) {
          this.currentRow = index;
        }
      });
      this.flag.editDriverPhoto = !this.flag.editDriverPhoto;
    },
    //删除图片
    deleteImg(field) {
      this.tableData.forEach((item) => {
        if (item.VehicleLicenseFrontPageURL == field.picUrl) {
          item.VehicleLicenseFrontPageURL = null;
        } else if (item.VehicleLicenseSubPageOnURL == field.picUrl) {
          item.VehicleLicenseSubPageOnURL = null;
        } else if (item.VehicleLicenseSubPageBackURL == field.picUrl) {
          item.VehicleLicenseSubPageBackURL = null;
        } else if (item.VehicleRTPUTL == field.picUrl) {
          item.VehicleRTPUTL = null;
        } else if (item.AdditionalLicenseURL == field.picUrl) {
          item.AdditionalLicenseURL = null;
        }
      });
      field.uploaded = false;
      field.loading = false;
      field.picUrl = "";
    },
    //删除批量导入里得某一个
    delListItem(index) {
      this.$confirm("是否删除该照片?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.bulkImportList.splice(index, 1);
        })
        .catch(() => {});
    },
    // 设置被删除行的样式
    setDeletedRow({ row, rowIndex }) {
      if (row.IsTrouble) {
        return "deletedRowStyle";
      }
    },

    //计算table高度(动态设置table高度)
    getTableHeight() {
      let tableH = 250; // 页面其余元素的高度(估值)
      this.$nextTick(() => {
        let formH = this.$refs.form.$el.offsetHeight; // form 查询表单的高度
        this.tableHeight =
          window.innerHeight - tableH - formH <= 150
            ? 150
            : window.innerHeight - tableH - formH;
      });
    },
  },
  mounted() {
    //挂载window.onresize事件(动态设置table高度)
    let _this = this;
    window.onresize = () => {
      if (_this.resizeFlag) {
        clearTimeout(_this.resizeFlag);
      }
      _this.resizeFlag = setTimeout(() => {
        _this.getTableHeight();
        _this.resizeFlag = null;
      }, 100);
    };
  },
  watch: {
    "flag.isBatch"(val) {
      if (val == false) {
        this.tableData = [];
        this.IsMyTC = "全部";
        this.batchListAttribute = "0";
        this.$refs.multipleTable.clearSelection();
        this.vehiclesDraftInfo();
        this.loading = false;
      }
    },
    "flag.editDriverPhoto"(val) {
      if (!val) {
        //每次关闭编辑的dialog时清空里边的数据
        this.editDriverForm.driveron.picUrl = "";
        this.editDriverForm.driverFace.picUrl = "";
        this.editDriverForm.driverBack.picUrl = "";
        this.editDriverForm.conveyLicenceUrl.picUrl = "";
        this.editDriverForm.elsePapers.picUrl = "";
        //清空图片上传组件的状态
        this.editDriverForm.driveron.loading = false;
        this.editDriverForm.driveron.uploaded = false;
        this.editDriverForm.driverFace.loading = false;
        this.editDriverForm.driverFace.uploaded = false;
        this.editDriverForm.driverBack.loading = false;
        this.editDriverForm.driverBack.uploaded = false;
        this.editDriverForm.conveyLicenceUrl.loading = false;
        this.editDriverForm.conveyLicenceUrl.uploaded = false;
        this.editDriverForm.elsePapers.loading = false;
        this.editDriverForm.elsePapers.uploaded = false;
      } else {
        //每次打开的时候需要填充编辑dialog的数据
        // 车辆行驶证副页(正页)
        this.editDriverForm.driveron.picUrl = this.tableData[
          this.currentRow
        ].VehicleLicenseSubPageOnURL;
        // 车辆行驶证正页(正面)
        this.editDriverForm.driverFace.picUrl = this.tableData[
          this.currentRow
        ].VehicleLicenseFrontPageURL;
        // 车辆行驶证副页(反面)
        this.editDriverForm.driverBack.picUrl = this.tableData[
          this.currentRow
        ].VehicleLicenseSubPageBackURL;

        this.editDriverForm.conveyLicenceUrl.picUrl = this.tableData[
          this.currentRow
        ].VehicleRTPUTL;
        this.editDriverForm.elsePapers.picUrl = this.tableData[
          this.currentRow
        ].AdditionalLicenseURL;
      }
    },
  },
  components: {
    UploadImg,
  },
};
</script>

<style lang="scss">
.bulkImportPage {
  .vxe-table--render-default .vxe-body--column.col--ellipsis > .vxe-cell {
    max-height: 100px;
  }

  .vxe-table--render-default .vxe-body--column.col--ellipsis,
  .vxe-table--render-default.vxe-editable .vxe-body--column {
    height: 100px;
  }

  .isBatch {
    .el-dialog__body {
      padding: 0px 20px 30px 20px;
    }
  }
}

.deletedRowStyle {
  background-color: #ebc0c0 !important;
}
</style>

<style lang="scss" scoped>
@import "../../../../assets/style/variable.scss";

.bulkImportPage {
  .tableWrap {
    border-width: 10px;
    background: white;
    // height: calc(100vh - 70px);
    border-style: solid;
    border-color: white;
    // overflow: hidden;
    // overflow-x: hidden;
    // overflow-y: scroll;
  }

  .top_box {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
  }

  .head {
    padding-bottom: 10px;
    // border-bottom: 1px solid $borderGray;
  }

  .funcs {
    margin-bottom: 10px;
  }

  .el-table {
    img {
      width: 50px;
      height: 50px;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .driverInfo {
    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #eee;
    }

    ::v-deep .el-dialog__footer {
      border-top: 1px solid #eee;
      text-align: center;
    }

    ::v-deep .el-dialog__body {
      padding: 20px;
    }

    .body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .hint {
        font-size: 16px;
        font-weight: bold;
        line-height: 26px;

        span {
          color: #ff2323;
          font-size: 16px;
          font-weight: bold;
        }
      }

      .download {
        color: $primary;
        cursor: pointer;
      }
    }
  }

  .driverPhoto {
    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #eee;
    }

    ::v-deep .el-dialog__footer {
      border-top: 1px solid #eee;
      text-align: center;
    }

    ::v-deep .el-dialog__body {
      padding: 20px;
    }

    .body {
      .uploadArea {
        .upload {
          width: 126px;
          height: 84px;
          margin: 0 0 10px 0;
          cursor: pointer;
          background-image: url("../../../../assets/image/upload.png");
          background-size: 100%;
          cursor: pointer;
        }

        .bulkImportList {
          max-height: 400px;
          overflow-y: scroll;
          display: flex;
          flex-wrap: wrap;
          margin-top: 10px;

          .item {
            position: relative;
            margin: 0 10px 10px 0;
            display: flex;
            flex-direction: column;

            img {
              width: 126px;
              height: 84px;
              border-radius: 10px;
            }

            span {
              padding-top: 5px;
              font-size: 12px;
              color: $textGray2;
            }

            i {
              position: absolute;
              top: 0px;
              right: 0px;
              color: rgb(219, 9, 9);
              cursor: pointer;
              padding: 10px;
            }
          }
        }
      }

      .hint {
        font-size: 16px;
        font-weight: bold;
        line-height: 26px;

        span {
          color: #ff2323;
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }

  .editDriverForm {
    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #eee;
    }

    ::v-deep .el-dialog__footer {
      border-top: 1px solid #eee;
      text-align: center;
    }

    ::v-deep .el-dialog__body {
      padding: 20px;
    }

    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      .el-upload__tip {
        color: $textGray2;
      }

      .upBox {
        text-align: center;

        .upText {
          font-size: 14px;
          font-weight: bold;
          margin: 10px 0;
          color: #333333;
        }
      }
    }
  }
}
</style>
